'use client'

import pluralize from 'pluralize'
import { ComponentProps, useMemo, useState } from 'react'
import styled from 'styled-components'

import { ColorSwatch } from '@syconium/little-miss-figgy/dist/components/ColorSwatch'
import { MediaAsContents } from '@syconium/little-miss-figgy/dist/components/Media'
import { ScreenReaderOnly } from '@syconium/little-miss-figgy/dist/components/ScreenReaderOnly'
import { TypeStyleBodyLarge } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'

import { gql } from '../../../../../__generated__/graphql/catalog/gql'
import { GetPageCollectionSectionQuery } from '../../../../../__generated__/graphql/catalog/graphql'
import { useNextQuery } from '../../../../../app/_hooks/useNextQuery'
import { trackEvent } from '../../../../../lib/analytics'
import { QueryStatus } from '../../../../../lib/hooks/types'
import { degenderProductGroupTitle } from '../../../../../lib/utils/domain'
import { useTrackProductInteraction } from '../../../../_providers/TrackingProvider.client'
import { MaxIterationsVideo } from '../../../media/MaxIterationsVideo.client'
import { NextLink } from '../../../navigation/NextLink'
import {
  PriceRangeForProductGroup,
  derivePricingForProductGroup,
} from '../../../pricing/PriceRangeForProductGroup.client'
import {
  ProductTileColorCount,
  ProductTileColorName,
  ProductTileColors,
  ProductTileColorsSkeleton,
  ProductTileImage,
  ProductTileImageSkeleton,
  ProductTilePrice,
  ProductTilePriceSkeleton,
  ProductTileTitle,
  ProductTileTitleSkeleton,
} from '../../../tiles/ProductTile.client'
import { LIFTED_BUTTONS_DEFAULT_PIXELS, Tile, TilesLayout } from '../../../tiles/Tiles.client'
import {
  PageSection,
  PageSectionContent,
  sectionSideGutterFromMdAndExpanded,
  sectionSideGutterUntilMd,
} from '../../PageSection.client'
import { ContentfulStyledImage } from '../components/ContentfulStyledImage.client'
import { ContentfulStyledLink } from '../components/ContentfulStyledLink.client'
import { ContentfulStyledText } from '../components/ContentfulStyledText.client'
import { useContentfulPageSectionContext } from '../ContentfulPageSectionProvider.client'
import { ContentfulPageSectionProps } from '../ContentfulPageSections.server'

export const ContentfulPageCollectionSectionHeader = styled.div`
  margin-bottom: ${o => o.theme.spacing(6)};
  min-height: 25px;
  display: flex;
  column-gap: ${o => o.theme.spacing(5)};
  row-gap: ${o => o.theme.spacing(4)};
  flex-direction: column;

  ${fromMd} {
    min-height: 42px;
    flex-direction: row;
  }
`

export const ContentfulPageCollectionSectionTitleWrapper = styled.div<{
  $textAlign?: {
    sm: 'center' | 'left' | 'right'
    md: 'center' | 'left' | 'right'
  }
}>`
  flex: 1;

  ${o =>
    o.$textAlign
      ? `
      ${untilMd} {
        text-align: ${o.$textAlign.sm};
      }
      ${fromMd} {
        text-align: ${o.$textAlign.md};
      }
    `
      : null};
`

const ContentfulPageCollectionSectionLinksWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${o => o.theme.spacing(4)};
`

const ContentfulPageCollectionSectionColorFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  ${fromMd} {
    padding-bottom: 30px;
  }
  padding-bottom: 16px;
  align-items: center;
`

const ContentfulPageCollectionSectionColorFilterLabel = styled(TypeStyleBodyLarge)`
  font-style: italic;
`

const ContentfulPageCollectionSectionFeaturedAssetWrapper = styled.div`
  margin-bottom: ${o => o.theme.spacing(5)};
`

type ContentfulPageCollectionSectionProps = ContentfulPageSectionProps & {
  contentfulSection: Extract<
    NonNullable<NonNullable<GetPageCollectionSectionQuery['pageViewSection']>>,
    { __typename: 'CollectionSection' }
  >
  scrollBackLabel: ComponentProps<typeof TilesLayout>['scrollBackLabel']
  scrollForwardLabel: ComponentProps<typeof TilesLayout>['scrollForwardLabel']
  colorCountText: string
}

export const ContentfulPageCollectionSection = ({
  scrollBackLabel,
  scrollForwardLabel,
  contentfulSection,
  pageSectionIndex,
  colorCountText,
}: ContentfulPageCollectionSectionProps) => {
  const sectionContext = useContentfulPageSectionContext()

  const {
    data: collectionData,
    loading: collectionDataLoading,
    error: collectionDataError,
  } = useNextQuery(
    gql(`
      query GetPageCollectionSectionCollection($collectionHandle: String!, $first: Int!) {
        collection: optimizedCollection(handle: $collectionHandle) {
          items(first: $first) {
            nodes {
              id
              shopifyId
              handle
              category
              colorInfo {
                handle
                name
                rawName
              }
              images {
                nodes {
                  source
                }
              }
              productGroup {
                handle
                title
                numColors
                priceRange {
                  min
                  max
                }
              }
              defaultVariant {
                priceDetails {
                  price {
                    amount
                    currency
                  }
                  discountPrice {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    `),
    {
      variables: {
        collectionHandle: contentfulSection.collectionHandle,
        first: contentfulSection.productCountLimit ?? 48,
      },
    }
  )

  const collection = collectionData?.collection

  const status = useMemo<Exclude<QueryStatus, 'idle'>>(() => {
    if (collectionDataLoading) return 'pending'
    if (collectionDataError || !collection) return 'rejected'
    return 'resolved'
  }, [collection, collectionDataError, collectionDataLoading])

  const {
    links = [],
    collectionHandle,
    colorsForFilters,
    featuredImages,
    featuredImage,
    featuredInlineVideoDesktop,
    featuredInlineVideoMobile,
    hideColors,
    hidePrice,
    titleText,
    subtitleText,
    titleLockupImage,
  } = contentfulSection

  const [activeFilterIndex, setActiveFilterIndex] = useState(0)

  const activeFeaturedImageData: NonNullable<typeof contentfulSection>['featuredImage'] =
    featuredImages?.[activeFilterIndex]
      ? featuredImages[activeFilterIndex] ?? null
      : featuredImage ?? null

  const activeFeaturedImage = useMemo(() => {
    return activeFeaturedImageData ? (
      <ContentfulStyledImage
        {...activeFeaturedImageData}
        maxWidthDesktop={null}
        maxWidthMobile={null}
        bottomMargin={0}
        loading='lazy'
        widths={{ unit: 'vw', sm: 100, md: 50 }}
        aspectRatioDesktop={activeFeaturedImageData.aspectRatioDesktop ?? 259 / 212}
      />
    ) : null
  }, [activeFeaturedImageData])

  const featuredVideo =
    featuredInlineVideoDesktop || featuredInlineVideoMobile
      ? {
          featuredInlineVideoDesktop: featuredInlineVideoDesktop ?? featuredInlineVideoMobile,
          featuredInlineVideoMobile: featuredInlineVideoMobile ?? featuredInlineVideoDesktop,
          alt: titleText?.title ?? '',
        }
      : null

  const tilesPerViewportWidth = useMemo(() => {
    return {
      sm: 2,
      md: 4,
    }
  }, [])
  const tilesLayoutChoices = useMemo(() => {
    return {
      sm: 'slider',
      md: 'slider',
    } as const
  }, [])
  const tilesGutterChoices = useMemo(() => {
    return {
      sm: sectionSideGutterUntilMd,
      md: sectionSideGutterFromMdAndExpanded,
    } as const
  }, [])
  const skeletonTiles = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [])
  const liftedButtons = !hideColors || !hidePrice

  const filteredProducts = useMemo(() => {
    const products = (collection?.items?.nodes ?? []).map(collectionItem => {
      const productGroup = collectionItem.productGroup
      const product = {
        category: collectionItem.category,
        colorDisplayName: collectionItem.colorInfo?.name,
        colorRawName: collectionItem.colorInfo?.rawName,
        colorCount: productGroup.numColors,
        pricing: collectionItem.defaultVariant.priceDetails?.price
          ? derivePricingForProductGroup({
              products: [
                {
                  priceRange: productGroup.priceRange,
                  defaultVariant: {
                    price: collectionItem.defaultVariant.priceDetails.price.amount,
                    discountPrice: collectionItem.defaultVariant.priceDetails.discountPrice?.amount,
                  },
                },
              ],
            })
          : undefined,
        handle: collectionItem.handle ?? '',
        id: collectionItem.id,
        image: collectionItem.images?.nodes[0]?.source,
        productGroupHandle: productGroup.handle,
        shopifyId: collectionItem.shopifyId,
        title: productGroup.title ? degenderProductGroupTitle(productGroup.title) : '',
      }
      return product
    })

    if (!colorsForFilters) return products
    return products.filter(
      product => product.colorDisplayName === colorsForFilters[activeFilterIndex]?.name
    )
  }, [activeFilterIndex, collection?.items?.nodes, colorsForFilters])

  const { trackProductInteraction } = useTrackProductInteraction()

  return (
    <PageSection>
      <PageSectionContent gutterSize='expanded'>
        {!!(titleText || links.length > 0) && (
          <ContentfulPageCollectionSectionHeader>
            {(!!titleText || !!subtitleText) && (
              <ContentfulPageCollectionSectionTitleWrapper>
                {titleLockupImage &&
                (titleLockupImage.imageDesktop || titleLockupImage.imageMobile) ? (
                  <ContentfulStyledImage
                    loading={'lazy'}
                    widths={{ unit: 'vw', sm: 100, md: 100 }}
                    transparentBackground={true}
                    {...titleLockupImage}
                  />
                ) : null}
                {!!titleText && <ContentfulStyledText {...titleText} />}
                {!!subtitleText && <ContentfulStyledText {...subtitleText} />}
              </ContentfulPageCollectionSectionTitleWrapper>
            )}

            {links.length > 0 ? (
              <ContentfulPageCollectionSectionLinksWrapper>
                {links.map(link => {
                  return (
                    <ContentfulStyledLink
                      key={`${link.url}:${link.text}`}
                      {...link}
                      analyticsContext={{
                        ...trackEvent({
                          category: sectionContext.pageSectionAnalyticsName,
                          action: 'click shop all cta',
                          label: link.url,
                          pageSectionName: titleText?.title ?? collectionHandle,
                          pageSectionIndex,
                          correspondingAsset:
                            activeFeaturedImageData?.imageDesktop ??
                            featuredVideo?.featuredInlineVideoDesktop ??
                            undefined,
                        }),
                      }}
                    />
                  )
                })}
              </ContentfulPageCollectionSectionLinksWrapper>
            ) : null}
          </ContentfulPageCollectionSectionHeader>
        )}

        {colorsForFilters && colorsForFilters.length > 0 ? (
          <ContentfulPageCollectionSectionColorFilters>
            {colorsForFilters.map((color, index) => (
              <button
                onClick={() => setActiveFilterIndex(index)}
                key={color.name}
                {...trackEvent({
                  category: sectionContext.pageSectionAnalyticsName,
                  action: 'click color filter',
                  label: color.name,
                  pageSectionName: titleText?.title ?? collectionHandle,
                  pageSectionIndex,
                  correspondingAsset: activeFeaturedImageData?.imageDesktop ?? undefined,
                })}
              >
                <ScreenReaderOnly>{color.name}</ScreenReaderOnly>
                <ColorSwatch
                  size={'large'}
                  hexCodes={color?.hexCodes}
                  selected={index === activeFilterIndex}
                />
              </button>
            ))}
            <ContentfulPageCollectionSectionColorFilterLabel color='secondary'>
              {colorsForFilters[activeFilterIndex]?.name}
            </ContentfulPageCollectionSectionColorFilterLabel>
          </ContentfulPageCollectionSectionColorFilters>
        ) : null}

        <MediaAsContents lessThan='md'>
          {activeFeaturedImage ? (
            <ContentfulPageCollectionSectionFeaturedAssetWrapper>
              {activeFeaturedImage}
            </ContentfulPageCollectionSectionFeaturedAssetWrapper>
          ) : featuredVideo?.featuredInlineVideoMobile ? (
            <ContentfulPageCollectionSectionFeaturedAssetWrapper>
              <MaxIterationsVideo
                autoPlay
                loop
                maxIterations={5}
                muted
                playsInline
                src={featuredVideo.featuredInlineVideoMobile}
                aspectRatios={null} // Technically we should be providing this from Contentful... so we don't have CLS.
              />
            </ContentfulPageCollectionSectionFeaturedAssetWrapper>
          ) : null}
        </MediaAsContents>
      </PageSectionContent>

      <PageSectionContent gutterSize='none'>
        <TilesLayout
          scrollBackLabel={scrollBackLabel}
          scrollForwardLabel={scrollForwardLabel}
          liftButtonsPixels={liftedButtons ? LIFTED_BUTTONS_DEFAULT_PIXELS : 0}
          layout={tilesLayoutChoices}
          visibleTiles={tilesPerViewportWidth}
          gutters={tilesGutterChoices}
        >
          {activeFeaturedImage ? (
            <MediaAsContents greaterThanOrEqual='md'>
              <Tile
                visibleTiles={{
                  sm: 2,
                  md: 2,
                }}
              >
                {activeFeaturedImage}
              </Tile>
            </MediaAsContents>
          ) : featuredVideo?.featuredInlineVideoDesktop ? (
            <MediaAsContents greaterThanOrEqual='md'>
              <Tile
                visibleTiles={{
                  sm: 2,
                  md: 2,
                }}
              >
                <MaxIterationsVideo
                  autoPlay
                  loop
                  maxIterations={5}
                  muted
                  playsInline
                  src={featuredVideo.featuredInlineVideoDesktop}
                  aspectRatios={null} // Technically we should be providing this from Contentful... so we don't have CLS.
                />
              </Tile>
            </MediaAsContents>
          ) : null}

          {status === 'pending'
            ? skeletonTiles.map((_, index) => {
                return (
                  <Tile key={index}>
                    <ProductTileImageSkeleton aspectRatio={'slider'} />
                    <ProductTileTitleSkeleton />
                    {!hideColors ? <ProductTileColorsSkeleton /> : null}
                    {!hidePrice ? <ProductTilePriceSkeleton /> : null}
                  </Tile>
                )
              })
            : filteredProducts.map((filteredProduct, index) => {
                if (!filteredProduct.image) return null

                const productUrl =
                  filteredProduct.category === 'Product Kit'
                    ? `/kits/${filteredProduct.productGroupHandle}`
                    : `/products/${filteredProduct.productGroupHandle}?color=${filteredProduct.colorRawName}`

                return (
                  <Tile
                    key={`${filteredProduct.id}${index}`}
                    {...trackEvent({
                      category: sectionContext.pageSectionAnalyticsName ?? 'product-carousel-tile',
                      action: 'click tile',
                      label: `tile-${index + 1}`,
                      value: filteredProduct.title,
                      pageSectionIndex: pageSectionIndex,
                      pageSectionName: sectionContext.pageSectionAnalyticsName,
                      correspondingAsset: filteredProduct.image,
                    })}
                  >
                    <NextLink
                      href={productUrl}
                      onClick={() => {
                        trackProductInteraction({
                          handle: filteredProduct.handle,
                          id: filteredProduct.id,
                          shopifyId: filteredProduct.shopifyId,
                        })
                      }}
                    >
                      <ProductTileImage
                        alt={filteredProduct.title}
                        loading='lazy'
                        src={filteredProduct.image}
                        aspectRatio={'slider'}
                        widths={{
                          unit: 'vw',
                          sm: 100 / tilesPerViewportWidth.sm,
                          md: 100 / tilesPerViewportWidth.md,
                        }}
                      />
                      <ProductTileTitle>{filteredProduct.title}</ProductTileTitle>
                      {!hideColors ? (
                        <ProductTileColors>
                          {filteredProduct.colorRawName !== 'No Color' && (
                            <ProductTileColorName>
                              {filteredProduct.colorDisplayName}
                            </ProductTileColorName>
                          )}
                          <ProductTileColorCount>
                            {filteredProduct.colorCount}{' '}
                            {pluralize(colorCountText, filteredProduct.colorCount)}
                          </ProductTileColorCount>
                        </ProductTileColors>
                      ) : null}
                      {!hidePrice && filteredProduct.pricing ? (
                        <ProductTilePrice>
                          <PriceRangeForProductGroup
                            {...filteredProduct.pricing}
                            upToPercentageOff={null}
                            percentageOff={null}
                          />
                        </ProductTilePrice>
                      ) : null}
                    </NextLink>
                  </Tile>
                )
              })}
        </TilesLayout>
      </PageSectionContent>
    </PageSection>
  )
}
